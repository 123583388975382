<template> 
        <div style="height:100%;" >
            <a-layout style="height:100%;">
                <a-layout-content style="height:100%;">
                    <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                        <a-layout style="height:100%;background:#fff;padding:0 20px;">
                            <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">                        
                                <a-row>
                                    <a-col :span="20">
                                        <a-form layout="horizontal" style="padding:0 20px;height:auto;">
                                            <a-form-item label="" style="margin-bottom:10px;"
                                                         :label-col="formItemLayout.labelCol"
                                                         :wrapper-col="formItemLayout.wrapperCol">
                                                <a-form layout='inline'>
                                                    <a-form-item label="状态">
                                                        <a-radio-group default-value="StoreState" v-model="StoreState" @change="QueryData">
                                                        <a-radio-button value="0">全部</a-radio-button>
                                                        <a-radio-button value="1">未发布</a-radio-button>
                                                        <a-radio-button value="2">已发布</a-radio-button>
                                                    </a-radio-group>
                                                    </a-form-item>
                                                </a-form>
                                            </a-form-item>
                                            <a-form-item label="" style="margin-bottom:10px;"
                                                         :label-col="formItemLayout.labelCol"
                                                         :wrapper-col="formItemLayout.wrapperCol">
                                                <a-form layout='inline'>
                                                    <a-form-item label="类型">
                                                        <a-radio-group default-value="StoreType" v-model="StoreType" @change="QueryData">
                                                        <a-radio-button value="0">全部</a-radio-button>
                                                        <a-radio-button value="1">朋友圈</a-radio-button>
                                                        <a-radio-button value="2">推广文章</a-radio-button>
                                                        </a-radio-group>
                                                    </a-form-item>
                                                </a-form>

                                            </a-form-item>
                                            <a-form-item label="" style="margin-bottom:0px;"
                                                         :label-col="formItemLayout.labelCol"
                                                         :wrapper-col="formItemLayout.wrapperCol">
                                                <a-form layout='inline'>
                                                    <a-form-item label="素材">
                                                        <a-input placeholder="输入素材标题" v-model="Titles" :value="Titles" style="width:278px;" />
                                                    </a-form-item>

                                                    <a-form-item label="">
                                                        <a-button type="primary" class="margin-r" @click="QueryData">
                                                            查询
                                                        </a-button>

                                                    </a-form-item>
                                                </a-form>
                                            </a-form-item>
                                        </a-form>
                                    </a-col>
                                    <a-col :span="4" class="textal_r">
                                        <a-button type="primary" @click="AddRow(0)">创建新素材</a-button>
                                    </a-col>
                                </a-row>
                            </a-layout-header>
                            <a-table   bordered :columns="columns" rowKey="ID" :data-source="Material" :pagination="pagination" @change="pagerChange">
                                <span slot="action" slot-scope="text, record">
                                    <a-button v-if="record.StoreState == 1" type="link" size="small" @click="UpdateState(record)">发布</a-button>
                                    <a-button v-if="record.StoreState == 2" type="link" size="small" @click="UpdateState(record)">撤回</a-button>
                                    <a-divider type="vertical"></a-divider>
                                    <a-button v-if="record.StoreState == 1" type="link" size="small" @click="EditRow(record)">编辑</a-button>
                                    <a-button v-if="record.StoreState == 2" type="link" size="small" @click="SelectRow(record)">详情</a-button>
                                    <a-divider v-if="record.StoreState == 1" type="vertical"></a-divider>
                                    <a-button v-if="record.StoreState == 1" type="link" size="small" @click="DelRow(record)">删除</a-button>
                                </span>
                              </a-table>
                        </a-layout>

                    </div>
                </a-layout-content>

            </a-layout>
        </div> 
</template>
<script type="text/javascript">
 import http from "@/Plugin/Http.js";
    export default {
       name: "MaterialList",
        data() {
            return {
                Material:[],
                MaterialList: [],
                columns:[
                    {
                        title: "素材图片",
                        width: 100,
                        align:"center",
                        customRender: (text, item) => {
                            if (item.TitleImg != null && item.TitleImg != "") {
                                return <div><img src={item.TitleImg} width="60" height="60" /></div>
                            } else {
                                return <div><img src="/image/tjwz.png" width="60" height="60" /></div>
                            }
                        }
                    },
                    {
                         title: "素材标题",
                         dataIndex: "Titles",
                         width: 360
                    },
                    {
                        title: "素材状态", align: "center",
                         dataIndex: "StoreStateName",
                         width: 120
                    },
                    {
                        title: "素材类型",
                        dataIndex: "StoreTypeName",
                        width: 300,
                        align: "center",
                    },
                    {
                         title: "操作",
                         align: "center",
                         scopedSlots: { customRender: 'action' },
                         width: 400
                    }
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                     total: 0,
                     defaultPageSize: 10,
                     current: 1,
                     showTotal: total => `共 ${total} 条数据`
                },
                StoreState: "0",
                StoreType: "0",
                Titles: ""
            };
        },
        methods:{
          //查询事件
           QueryData() {
               this.loadData();
            },
           //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function(item) {
                 var self = this;
                 self.pagination.current = item.current;
                 self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/MaterialList",
                    data: {
                        ID: self.ID,
                        CustomID: self.CustomID,
                        Titles: self.Titles,
                        TitleImg: self.TitleImg,
                        StoreID: self.StoreID,
                        StoreType: self.StoreType,
                        StoreState: self.StoreState, 
                        StoreTypeName: self.StoreTypeName,
                        StoreStateNmae: self.StoreStateNmae,
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.Material = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            AddRow(e) {
                var id = 0;
                if (e != '0') {
                    id = e.ID;
                }
                this.$router.push({
                    name: "Material_Add",
                    query: { id: id }
                });
            },
            EditRow(e) {
                var id = 0;
                var type = 0;
                if (e != '0') {
                    id = e.StoreID;
                    type = e.StoreType;
                }
                if (type == 1)
                {
                  this.$router.push({
                    name: "Material_MomentDetail",
                    query: { id: id , type: type}
                  });
                }
                if (type == 2)
                {
                  this.$router.push({
                    name: "Material_ExtensionDetail",
                    query: { id: id , type: type}
                  });
                }
            },
            SelectRow(e) {
                var id = 0;
                var type = 0;
                if (e != '0') {
                    id = e.StoreID;
                    type = e.StoreType;
                }
                if (type == 1)
                {
                  this.$router.push({
                    name: "Material_MomentDetail",
                    query: { id: id , type: type , rowType: 'select'}
                  });
                }
                if (type == 2)
                {
                  this.$router.push({
                    name: "Material_ExtensionDetail",
                    query: { id: id , type: type , rowType: 'select'}
                  });
                }
            },
            DelRow(e) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + e.Titles,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Material/DeleteMaterial",
                            data: {
                                id: e.ID
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            UpdateState(e) {
                var self = this;
                var con = e.StoreState == 1 ? '发布' : '撤回';
                var status = e.StoreState;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Titles,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Material/UpdateState",
                            data: {
                                id: e.ID,
                                state: status
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            }
        },
         mounted() {
          this.loadData();
        }
    }
</script>



